import React from 'react';
import './Summery.css';
import CV from '../../assets/cv/cv.pdf';

const Summery = () => {
  return (
    <section id='summery'>
      <div className='summery-heading' data-aos="fade-right">
        <strong>My Skillset</strong>
        <h2>Computer programmer skills often combine creativity with technical and analytical elements. </h2>

        <a href={CV} download className='btn-link'>
            Get CV <i className="fa-solid fa-download"></i>

        </a>

      </div>

      <div className='summery-details' data-aos="fade-left">
      Computer programmers need to have a wide range of skills to perform the many responsibilities of this challenging and rewarding role. The most effective programmers combine technical capability with soft skills such as communication and the ability to work as part of a team. Aspiring computer programmers need to be able to demonstrate both types of skills.
      </div>
    </section>
  )
}

export default Summery
